import React,{useState, useEffect} from 'react';
import { Col, Carousel, Row, Card } from 'react-bootstrap';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import testimonialbg from '../assets/images/testimonialbg.png';
import './Navv.css';
import Adrian from '../assets/images/adrian.png';
import AdirA from '../assets/images/AdirA.jpeg';
import allevi8 from '../assets/images/allevi8.png';
import vector from '../assets/images/Vector4.png'
import NavBar from '../Components/NavBar';

const Data = [
    [
        {
            img: Adrian,
            para: 'The cooperation between us was excellent and fruitful for our project. I especially appreciated creative contributions from your side which went far beyond mere execution of the task description. I’m already thinking about few follow-up projects.',
            name: 'Adrian',
            // para1: 'P.E.S. College, Aurangabad'
        },
        {
            img: AdirA,
            para: "We have worked with Intellection for 5 years. Their differentiating quality is their ability to problem solve. They not only enjoy the challenge of a tough task, but also try to be creative in the mundane ones.",
            name: 'Monica Chaudhari',
            para1: 'CEO and co-founder, AdirA'
        },
        {
            img: allevi8,
            para: "Imageryworks has engaged Intellection for over 8 years, to develop and support its world-class Allevi8 meditation and mindfulness practices app and its back-end system",
            name: 'Saurabh Mishra',
            para1: 'Director, Imageryworks'
        }
        // Very pleasant to work with.
    ],
    // [
    //     {
    //         img: Test,
    //         para: 'We had entrusted Intellection Software & Technologies Pvt. Ltd. with automating our college ERP System. We have been extremely satisfied with both, the software as well as the service provided. ',
    //         name: 'Prof. Wadekar',
    //         para1: 'P.E.S. College, Aurangabad'
    //     },
    //     {
    //         img: Test,
    //         para: "We had entrusted Intellection Software & Technologies Pvt. Ltd. with automating our college ERP System. We have been extremely satisfied with both, the software as well as the service provided. ",
    //         name: 'Prof. Wadekar',
    //         para1: 'P.E.S. College, Aurangabad'
    //     },
    //     {
    //         img: Test,
    //         para: "We had entrusted Intellection Software & Technologies Pvt. Ltd. with automating our college ERP System. We have been extremely satisfied with both, the software as well as the service provided. ",
    //         name: 'Prof. Wadekar',
    //         para1: 'P.E.S. College, Aurangabad'
    //     }
    // ]
];

const Testimonial = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 992);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderSlides = () => {
        const slides = [];

        if (isSmallScreen) {
            Data.forEach((slide, index) => {
                slide.forEach((item, i) => {
                    slides.push(
                        <Carousel.Item key={`${index}-${i}`}>
                            <Row className="justify-content-center">
                                <Col sm={12} >
                                <Card style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
                                        <img src={item.img} alt="logo" height={70} width={70} style={{ marginBottom: '10px' }} />
                                        <p style={{ fontFamily: FONT.primaryFont, fontSize: 18, fontWeight: 700 }}>{item.name}</p>
                                        <p style={{ color: 'rgba(96, 96, 96, 1)', fontFamily: FONT.primaryFont, fontSize: 14, marginTop: '-10px' }}>{item.para1}</p>
                                        <img src={vector} alt="logo" height={20} width={20} style={{ margin: '20px 0px' }} />
                                        <p style={{ textAlign: 'justify', fontFamily: FONT.primaryFont, fontSize: 16 }}>{item.para}</p>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    );
                });
            });
        } else {
            Data.forEach((slide, index) => {
                slides.push(
                    <Carousel.Item key={index}>
                        <Row>
                            {slide.map((item, i) => (
                                <Col lg={4} md={4} key={i} >
                                    <Card style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
                                        <img src={item.img} alt="logo" height={70} width={70} style={{ marginBottom: '10px' }} />
                                        <p style={{ fontFamily: FONT.primaryFont, fontSize: 18, fontWeight: 700 }}>{item.name}</p>
                                        <p style={{ color: 'rgba(96, 96, 96, 1)', fontFamily: FONT.primaryFont, fontSize: 14, marginTop: '-10px' }}>{item.para1}</p>
                                        <img src={vector} alt="logo" height={20} width={20} style={{ margin: '20px 0px' }} />
                                        <p style={{ textAlign: "justify", fontFamily: FONT.primaryFont, fontSize: 16 }}>{item.para}</p>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Carousel.Item>
                );
            });
        }

        return slides;
    };

    return (
        <>
        <NavBar/>
            <div className="WhatWeDoComponent">
                <h3 style={{ color: COLORS.heading, fontSize: 34, fontWeight: 'bold', fontFamily: FONT.primaryFont, marginTop: 100, textAlign: 'center' }}>Testimonial</h3>
                <p style={{ fontSize: 21, fontFamily: FONT.primaryFont, textAlign: 'center' }}>Hear Directly from Our Satisfied Clients.</p>
            </div>
            <div style={{
                backgroundImage: `url(${testimonialbg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                border: '1px solid white',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className="WhatWeDoComponent" style={{ marginTop: '50px', marginBottom: 60 }}>
                    <Carousel indicators={false} interval={3000}>
                    {renderSlides()}
                        {/* {Data.map((slide, index) => (
                            <Carousel.Item key={index}>
                                <Row>
                                    {slide.map((item, i) => (
                                        <Col key={i} lg={4} md={4} sm={12} >
                                            <Card style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
                                                <img src={item.img} height={70} width={70} style={{ marginBottom: '10px' }} />
                                                <p style={{ fontFamily: FONT.primaryFont, fontSize: 18, fontWeight: 700 }}>{item.name}</p>
                                                <p style={{ color: 'rgba(96, 96, 96, 1)', fontFamily: FONT.primaryFont, fontSize: 14, marginTop: '-10px' }}>{item.para1}</p>
                                                <img src={vector} height={20} width={20} style={{ margin: '20px 0px' }} />
                                                <p style={{ textAlign: 'justify', fontFamily: FONT.primaryFont, fontSize: 16 }}>{item.para}</p>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Carousel.Item>
                        ))} */}
                    </Carousel>
                </div>
            </div>
        </>
    );
}

export default Testimonial;
