import React from 'react';
import { Button } from 'react-bootstrap';
import { BsArrowRight } from 'react-icons/bs';
import { FONT } from '../utils/Font';
import { useNavigate } from 'react-router-dom';

const ContactWithUs = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/contact-us'); // Replace '/contact' with the desired route
    };

    return (
        <>
            <div className="gradient-background" style={{ marginTop: 20,marginBottom:40 }}>
                <p style={{ fontFamily: FONT.primaryFont, fontSize: '18px' }}>Solutions tailored to your needs</p>
                <p style={{ fontFamily: FONT.fontBold, fontSize: '48px' }}>Let’s Work Together</p>
                <Button className='connectwithus' onClick={handleNavigate}>
                    Connect with Us<BsArrowRight style={{ marginLeft: '18px', marginTop: '-4px' }} />
                </Button>
            </div>
        </>
    );
};

export default ContactWithUs;
