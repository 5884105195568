import React from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import iElection from '../assets/images/iElection.png'

import Navv from './Navv'
import Form from 'react-bootstrap/Form';
import REVA from '../assets/images/ReVALogo.png';
import NavBar from './NavBar';


const IelectionDetailPage = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
         


            <div className='WhatWeDoComponent' style={{ marginTop: '100px', marginBottom: '100px' }}>
                {/* <img src={REVA} alt='Reva' width={200} height={150} /> */}
                <Row>
                    <Col lg={7} md={12}  >
                        <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.fontBold, fontSize: 20 }}>iElection</p>
                        <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}>The iElection platform is a breakthrough in managing elections in India, tackling the unique complexities of the electoral process. Designed with advanced data extraction techniques and linguistic algorithms, it groups individuals into family units, bridging the gap across multiple regional languages. This innovation greatly enhances a candidate's ability to manage voter data and streamline outreach. By catering to the vast and diverse population of India, the platform ensures efficient and effective election management.</p>
                        <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}> iElection was recognized as the "Highly Innovative Application of 2012" by the Government of Maharashtra, further cementing its impact on improving transparency and operational efficiency within the electoral system. Its ability to simplify the management of voter information while maintaining accuracy makes it a vital tool for large-scale election campaigns. Today, iElection continues to be a powerful resource for election organizers, contributing to the integrity and efficiency of the Indian democratic process.</p>
                    </Col>
                    <Col lg={5} style={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img src={iElection} alt="iElection" className='img-fluid' width={300} height={200} style={{ border: '1px solid grey', marginTop: 30 }} />
  </Col>

                </Row>
                <p>Our product was acknowledge by some of the top media channels including The Indian Express.</p>
                <a  href="https://indianexpress.com/article/cities/pune/now-eslips-for-voters-on-android/">Read More</a>

                {/* <div>

                   
                    <p style={{ textAlign: 'justify', marginTop: 10, width: '95%', fontFamily: FONT.primaryFont }}>ReVA goes beyond mere data analysis by empowering users to create customized target groups based on specific viewership and demographic criteria. These tailored segments can be analyzed and visualized using a variety of filters, offering a nuanced perspective on viewing trends. Whether you’re interested in a particular age group, geographic location, or content genre, ReVA provides the flexibility to focus on the metrics that matter most to your goals.</p>
                    <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}>Furthermore, ReVA takes your content strategy to the next level by recommending the most effective channels for displaying promotional material. By leveraging viewership data and advanced analytics, ReVA identifies the optimal platforms to maximize audience engagement, ensuring your promotional content reaches the right viewers at the right time.</p>
                    <p style={{ textAlign: 'justify', marginTop: 20, width: '95%', fontFamily: FONT.primaryFont }}>In summary, ReVA is not just a tool for analyzing viewership; it is a comprehensive platform designed to enhance your understanding of audience behavior, streamline your marketing strategies, and ultimately, drive better results. Whether you are a broadcaster, marketer, or content creator, ReVA offers the insights and analytics you need to stay ahead in a competitive landscape.</p>

                </div> */}
            </div>
        </>
    )
}
export default IelectionDetailPage