import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs';
import './NavBar.css'
const OurServicesCardComponent = ({ item, index, border }) => {
    return (
        <>
            {/* <Col key={index} lg={4} md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ alignItems: 'center' }}>

                 
                    <Link to={`/services/${item.id}`} style={{ textDecoration: 'none', color: 'black' }}>
                        <div style={{ borderRadius: 100, width: 150, height: 150, display: 'flex', justifyContent: 'center', margin: '0 auto', alignItems: 'center', boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)` }}>
                            <img src={item.img} alt={item.heading} width={70} height={70} />
                        </div>
                    </Link>

                    <div style={{ padding: 20, width: '80%', textAlign: 'center', margin: '0 auto' }}>
                        <Link to={`/services/${item.id}`} style={{ textDecoration: 'none', color: 'black' }}>
                            <p style={{ marginTop: 15, fontSize: 20, fontFamily: FONT.primaryFont, }}>{item.heading}</p>
                        </Link>

                    </div>

                </div>

            </Col> */}
            <Col key={index} lg={4} md={6} xs={12} className="d-flex align-items-stretch"  style={{ marginBottom: 20}}>
            <Link to={`/services/${item.category}`} style={{ textDecoration: 'none', color: 'black' }}>
                <div
                    className="hover-gradient-card d-flex flex-column justify-content-between"
                    style={{
                        border: '1px solid #CFCFCF',
                        padding: '20px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                       
                        flex: 1
                    }}
                >
                    <div style={{ padding: '0px 20px' }}>
                        <img
                            style={{ marginTop: 20 }}
                            src={item.img}
                            alt={item.heading}
                            width={70}
                            height={70}
                            
                        />
                        <p
                            style={{
                                marginTop: 45,
                                fontSize: 24,
                                lineHeight: '24px',
                                fontWeight: 600,
                                fontFamily: FONT.primaryFont,
                                color: 'rgba(61, 39, 77, 1)',
                            }}
                        >
                            {item.heading}
                        </p>
                        <p
                            style={{
                                marginTop: 15,
                                fontSize: 16,
                                lineHeight: '22px',
                                fontFamily: FONT.primaryFont,
                            }}
                        >
                            {item.para}
                        </p>
                    </div>
                    <BsArrowRight
                        style={{
                            marginLeft: '18px',
                            marginTop: '-4px',
                            width: '30px',
                        }}
                    />
                </div>
                </Link>
            </Col>

        </>
    )
}
export default OurServicesCardComponent