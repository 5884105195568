import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { COLORS } from "../utils/Colors";
import { FONT } from "../utils/Font";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import NavBar from "../Components/NavBar";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Helmet } from "react-helmet";

const BlogCategoryDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { blogCategory, blogCategoryDetails } = useParams();
  const [data, setData] = useState([]);
  const [otherCategories, setOtherCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(blogCategory, blogCategoryDetails)

  const handleBackClick = () => {
    navigate(`/${blogCategory}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LOCAL_URL}/blog.php?slug=${blogCategoryDetails}`
        );
        setData(response.data);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    const fetchOtherCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LOCAL_URL}/blog-category-items.php?slug=${blogCategory}`
        );
        setOtherCategories(response.data);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchOtherCategories();
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getNextItems(slug, count = 4) {
    const index = otherCategories.findIndex((item) => item.slug === slug);

    if (index === -1) {
      console.error(`Slug "${slug}" not found in the dataset.`);
      return [];
    }

    const result = [];
    for (let i = 1; i <= count; i++) {
      result.push(otherCategories[(index + i) % otherCategories.length]);
    }

    return result;
  }

  const getCategories = getNextItems(blogCategoryDetails);

  console.log(data);

  return (
    <>
      <Helmet>
        <title>Intellection: Our Products</title>
        <meta
          name="description"
          content="Intellection offers a wide range of innovative software solutions including web development, mobile app development, AI/ML services, chatbot development, and custom software tailored to business needs."
        />
        <meta name="keywords" content={data?.meta?.keywords} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={data?.meta && data?.meta["og:title"]} />
        <meta property="og:description" content={data?.meta && data?.meta["og:description"]} />
        <meta
          property="og:url"
          content="https://www.intellection.in/company-overview"
        />
        <meta property="og:type" content={data?.meta && data?.meta["og:type"]} />
      </Helmet>
      <NavBar />
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!loading && !error && (
        <div
          className="WhatWeDoComponent"
          style={{ marginTop: "100px", marginBottom: "100px" }}
        >
          <button
            style={{ border: "none", backgroundColor: "white" }}
            onClick={handleBackClick}
          >
            <FaArrowLeftLong
              style={{
                marginRight: "8px",
                color: "#6F2DA8",
                fontSize: "30px",
                marginBottom: 10,
              }}
            />
          </button>

          <Row>
            <Col lg={8} md={12}>
              <p
                style={{
                  color: COLORS.heading,
                  fontSize: 28,
                  fontWeight: "bold",
                  fontFamily: FONT.fontBold,
                }}
              >
                {data.title}
              </p>
              <img
                src={data.image}
                alt="Our Product"
                style={{ width: "100%", height: "auto", marginBottom: 10 }}
              />
              <div className="htmlContent">
                <div dangerouslySetInnerHTML={{ __html: data.content }} />
              </div>
            </Col>
            <Col lg={4} md={12} style={{ marginTop: 40 }}>
              <h4
                style={{
                  color: "rgb(111, 45, 168)",
                  padding: "10px 0px",
                  fontWeight: 700,
                }}
              >
                Suggested Blogs
              </h4>
              {getCategories?.map((category, index) => (
                <Row
                  style={{
                    borderBottom:
                      index + 1 !== getCategories?.length &&
                      "1px solid lightgrey",
                    padding: 10,
                  }}
                >
                  <Col lg={8}>
                    <p style={{ fontWeight: 500, lineHeight: 1.2 }}>
                      {category.content}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <img
                      src={category.image}
                      alt="Our Product"
                      style={{
                        width: "100%",
                        height: "80px",
                        marginBottom: 10,
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                as={Link}
                to={`/${blogCategory}`}
                className="contactUs"
                style={{ width: "100%" }}
              >
                See more blogs
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <style>
        {`
          .htmlContent h5 {
            font-family: ${FONT.fontBold};
          }
          .htmlContent h4 {
            color: rgb(111, 45, 168);
            font-weight: 700;
          }
      `}
      </style>
    </>
  );
};
export default BlogCategoryDetails;
