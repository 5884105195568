import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from './NavBar';
import '../Components/NavBar.css'
const NotFound = () => {
  return (
      <>
      <NavBar/>
    <Container style={{marginTop:'150px',marginBottom:'20px',textAlign:'center'}}>
      <Row>
        <Col>
       
          <h1>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
          <p><a href="/">Go back to Home</a></p>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default NotFound;
