import React from 'react';
import { Container } from 'react-bootstrap';
import tiss from '../assets/images/tiss_logo.png';
import solapurUniv from '../assets/images/Solapur_univ_logo.gif';
import logoRetina from '../assets/images/logo-edv-feller-retina.png';
import airtel from '../assets/images/og_airtel.png';
import igtr from '../assets/images/igtr_logo.png';
import Geo from '../assets/images/Geometric_Logo.jpg';
import vfit from '../assets/images/vfit.jpeg';
import AdirA from '../assets/images/AdirA.jpeg';
import bellybaloo from '../assets/images/bellybaloo.png';
import ISMELogo from '../assets/images/ISME-Logo.png';
import godrej from '../assets/images/Godrej_Logo.png';
import zequality from '../assets/images/zequality_logo.png';
import electronica from '../assets/images/electronica.jpeg';
import allevi8 from '../assets/images/allevi8.png';
import Barc from '../assets/images/barc_logo.png';
import { FONT } from '../utils/Font';
import './OurClient.css';

const OurClient = () => {
    const logos = [
        tiss, godrej, ISMELogo, Barc, airtel, Geo, igtr, solapurUniv, logoRetina, vfit,
        zequality, AdirA, electronica, allevi8, bellybaloo
    ];

    return (
        <Container className="my-5">
            <p style={{ fontSize: '35px', fontFamily: FONT.primaryFont, fontWeight: 600, textAlign: 'center' }}>
                Our Partner Ecosystem
            </p>
            <div className="infinite-carousel">
                <div className="logo-track">
                    {logos.map((logo, idx) => (
                        <img
                            key={idx}
                            className="logo-image"
                            src={logo}
                            alt={`Logo ${idx}`}
                            style={
                                logo === logoRetina
                                    ? { width: '250px' }
                                    : logo === electronica
                                    ? { height: '150px' }
                                    : logo === bellybaloo
                                    ? { height: '150px' }
                                    : logo === godrej
                                    ? { height: '100px' }
                                    : {}
                            }
                        />
                    ))}
                    {/* Duplicate logos for seamless scroll */}
                    {logos.map((logo, idx) => (
                        <img
                            key={idx + logos.length}
                            className="logo-image"
                            src={logo}
                            alt={`Logo ${idx}`}
                            style={
                                logo === logoRetina
                                    ? { width: '250px' }
                                    : logo === electronica
                                    ? { height: '150px' }
                                    : logo === bellybaloo
                                    ? { height: '150px' }
                                    : logo === godrej
                                    ? { height: '100px' }
                                    : {}
                            }
                        />
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default OurClient;
