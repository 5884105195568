import React, { useEffect } from 'react'
import About from '../Components/About'
import NavBar from '../Components/NavBar'
import OurVision from '../Components/OurVision'
import OurLeadership from '../Components/OurLeadership'
import Experience from '../Components/Experience'
import OurTeam from '../Components/OurTeam'
import OurValues from '../Components/OurValues'
import WhyChooseUs from '../Components/WhyChooseUs';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>About Intellection - Leading Software Solution Provider</title>
                <meta name="description" content="Learn more about Intellection, our mission, vision, leadership, and values. We offer innovative solutions across industries like Web Development, Mobile App Development, and Healthcare." />

                <meta name="keywords" content="About Intellection, Our Leadership, Our Values, Web Development, Mobile App Development,Next-Gen Technology Integration,Cross-Platform App Development,Educational Institution ERP Solutions,Agile Software Development" />
                
                <meta name="robots" content="index, follow" />

                {/* Open Graph tags for social sharing */}
                <meta property="og:title" content="About Intellection - Leading Software Solution Provider" />
                <meta property="og:description" content="Learn about Intellection's vision, leadership, and values as we deliver software solutions across industries like finance, healthcare, and media." />
                <meta property="og:url" content="https://www.intellection.in/company-overview" />
                <meta property="og:type" content="website" />

                {/* Image for Open Graph (LinkedIn/Facebook) */}
                <meta property="og:image" content="https://www.intellection.com/assets/images/IntellectionLogo.png" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Intellection - Leading Software Solution Provider" />
                <meta name="twitter:description" content="Discover Intellection's values, mission, and leadership in delivering software solutions across various industries." />
                <meta name="twitter:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" />
            </Helmet>

            <NavBar />
            <About />
            <OurVision />
            <OurValues />
            <WhyChooseUs />
        </>
    )
}
export default AboutUs