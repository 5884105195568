import { Container, Row, Col, Card } from "react-bootstrap";
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { FONT } from "../utils/Font";
import './Navv.css';



const OurProject = ({ data, category }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const params = new URLSearchParams(location.search);

    const handleCardClick = (project) => {
        console.log(project)
        navigate(`/project-details/${project?.category}/${project.id}`);
    };

    const capitalizeFirstLetter = (str) => {
        if (str.toLowerCase() === "media-entertainment") {
            return "Media & Entertainment";
        }
        if (str.toLowerCase() === "utility-solution") {
            return "Utility Solution";
        }
        return str
            .toLowerCase()
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match.toLowerCase()
            );
    };

    const categoryInTitleCase = data.length > 0 ? capitalizeFirstLetter(data[0].category) : 'All Categories';


    return (
        <div>
            <div className="custom-software-container">
                <p className="main-title" style={{ fontFamily: FONT.primaryFont }}>
                    Portfo<span className="highlighted">lio</span>
                </p>

                <div className="text-container">
                    <p className="text-line" style={{ fontFamily: FONT.primaryFont }}>
                        Customized solutions that have helped our clients achieve their specific
                    </p>
                    <p className="text-line" style={{ fontFamily: FONT.primaryFont }}>
                        business goals.
                    </p>
                </div>

                {category === null ? <h1></h1> : <h1>{categoryInTitleCase}</h1>}
            </div>
            {category === null ?
                <div className="gradient-background">
                     {/* <p className="overview-title">Overview</p> */}
                    <p className="overview-content">
                        In the "Portfolio" section, discover how our tailored software solutions drive success across various industries. We specialize in media and entertainment, healthcare, utility solutions, manufacturing, and education, delivering innovative technology that addresses industry-specific challenges. Our expertise ensures enhanced operational efficiency and strategic growth, demonstrating our commitment to transforming complex needs into actionable solutions.
                    </p>
                </div>
                :
                <div className="gradient-background">
                    {/* <p className="overview-title">Overview</p> */}
                    {categoryInTitleCase === "Healthcare" ? (
                        <p className="overview-content">
                            The healthcare industry encompasses a wide range of services, from patient care to medical devices and health technologies, playing a crucial role in improving quality of life and managing public health. However, it faces challenges like data management, patient engagement, and operational efficiency. Intellection provides solutions such as AI-driven patient data analytics to improve diagnosis and treatment outcomes, personalized care management systems to enhance patient engagement, and predictive analytics to optimize resource allocation and operational efficiency. Additionally, real-time health monitoring platforms and data security solutions ensure that healthcare providers can deliver efficient, safe, and personalized care while managing costs and improving overall healthcare outcomes.
                        </p>
                    ) : categoryInTitleCase === "Utility Solution" ? (
                        <p className="overview-content">
                            The utility solutions sector faces several challenges, including the need for extensive customization to meet specific client requirements, the complexity of integrating new solutions with existing legacy systems, and the necessity for scalability and adaptability as businesses grow. Intellection tackles these issues by delivering tailored software solutions that precisely address industry-specific needs, ensuring smooth integration with current systems, and providing scalable, flexible technologies that enhance operational efficiency and user satisfaction. This approach helps businesses overcome common hurdles and achieve better overall performance.
                        </p>
                    ) : categoryInTitleCase === "Education" ? (
                        <p className="overview-content">
                            The education sector faces challenges such as engaging students in a digital environment, managing diverse learning needs, and integrating new technologies with traditional teaching methods. Intellection addresses these challenges by providing tailored software solutions that enhance student engagement through interactive platforms, support personalized learning experiences, and ensure seamless integration of technology into educational settings. Our solutions aim to improve educational outcomes, streamline administrative processes, and adapt to the evolving needs of students and educators.
                        </p>
                    ) : categoryInTitleCase === "Media & Entertainment" ? (
                        <p className="overview-content">
                           The media and entertainment industry spans diverse sectors such as film, TV, music, and digital platforms, playing a key role in shaping culture and driving economic growth. However, it faces challenges like audience retention, measuring loyalty, and meeting the demand for personalized experiences. Intellection addresses these issues by offering AI-powered audience analytics to identify engagement patterns, personalized recommendation engines to enhance user experience, and churn prediction models to prevent audience loss. Additionally, real-time data dashboards and ad targeting optimization empower media companies to make data-driven decisions that improve engagement and revenue.
                        </p>
                    ) : categoryInTitleCase === "Manufacturing" ? (
                        <p className="overview-content">
                            The manufacturing sector encounters challenges such as optimizing production efficiency, managing supply chain complexities, and integrating advanced technologies with existing systems. Intellection addresses these issues by offering customized software solutions that enhance production processes, streamline supply chain management, and facilitate the integration of modern technologies. Our solutions focus on improving operational efficiency, reducing downtime, and providing actionable insights, thereby helping manufacturers achieve greater productivity and adapt to evolving industry demands.
                        </p>
                    ) : null}
                </div>
            }


            <div
                defaultActiveKey="All Works"
                id="uncontrolled-tab-example"
                className="mb-3 mt-5 tabsmain"
                style={{ border: 'none', cursor: 'pointer' }}
            >
                <Row>
                    {data?.map((item, index) => (
                        <Col key={index} lg={4} md={4} sm={4} className="carousel-item-col">
                            <Card className="text-white" style={{ marginBottom: 20 }} onClick={() => handleCardClick(item)}>
                                <Card.Img className='img-hover' src={item.img} alt={item.alt}  />
                                <div className="img-overlay">
                                    <Card.Title>{item.heading}</Card.Title>
                                    <Card.Text>{item.paragraph}</Card.Text>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default OurProject;
