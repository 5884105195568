import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Application from '../assets/images/application.png';
import CloudStorage from '../assets/images/cloud-storage.png';
import Code from '../assets/images/code.png';
import Coding from '../assets/images/coding.png';
import smartControl from '../assets/images/smart-control.png';
import Testing from '../assets/images/testing.png';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';
import './Navv.css';

const Data = [
    {
        img: Application,
        title: "Application Architecture",
        paragraph: 'Consideration of scalability and also reliability,  maintainability. Our applications are built with a modular architecture, allowing for easy maintenance and scalability.'
    },
    {
        img: CloudStorage,
        title: "Database",
        paragraph: 'We have expertise in handling large datasets in gigabytes. For structured data, we have worked with MySQL and PostgreSQL, and for unstructured data, we use MongoDB. Additionally, we have developed techniques to efficiently manage and process large volumes of data.'
    },
    {
        img: Code,
        title: "Coding Standards",
        paragraph: 'We follow industry coding standards to ensure code data integrity and security. It includes guidelines for code reusability, design patterns, performance optimization, and proper error handling.'
    },
    {
        img: Testing,
        title: "Testing",
        paragraph: 'We have experience in software testing using Jira for issue tracking and Confluence for documentation. We create and manage detailed test cases and user stories to ensure comprehensive testing and effective tracking of software quality.'
    },
    {
        img: Coding,
        title: "Code Review",
        paragraph: 'We have a structured code review process that involves multiple levels of oversight. Developers submit their code, which is carefully reviewed by both the team lead and the technical manager. This ensures adherence to coding standards and helps maintain consistency and high-quality output across the entire team.'
    },
    {
        img: smartControl,
        title: "Version Controlling",
        paragraph: 'We handle version control using Bitbucket and GitHub to efficiently track changes and manage code repositories. We ensure smooth collaboration among team members, maintain a clear history of code modifications, and facilitate effective branching and merging strategies throughout the development process.'
    },
];

const StandardsAndControls = () => {
    return (
        <>
            <div className="WhatWeDoComponent" style={{ marginTop: 40, marginBottom: 40 }}>
                <p className="qualitystandard" style={{ color: COLORS.heading, fontWeight: 600, fontFamily: FONT.primaryFont, textAlign: 'center' }}>Software Development <span style={{ color: 'rgba(111, 45, 168, 1)'}}> Cycle</span></p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p className='qualitystandardpara' style={{ fontSize: 20, color: 'rgba(0, 0, 0, 1)', textAlign: 'center', fontFamily: FONT.primaryFont }}>Our rigorous quality control processes ensures every product meets the highest standards of reliability, and security.</p>
                </div>
                <Row>
                    {Data.map((item, index) => (
                        <Col key={index} md={12} lg={6} >
                            <div style={{
                                border: '1px solid rgba(207, 207, 207, 1)',
                                position: 'relative',
                                marginTop: 30,
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '200px', // Set a minimum height
                                alignItems: 'stretch' // Ensure items stretch to full height
                            }}>
                                <div
                                    style={{
                                        borderRadius: '50%',
                                        width: 80,
                                        height: 80,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginBottom: 20
                                    }}
                                >
                                    <img src={item.img} alt="application" width={40} height={40} style={{ maxWidth: '100%', maxHeight: '100%', display: 'block' }} />
                                </div>

                                <div style={{
                                    padding: 10,
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between' // Makes content distribute evenly
                                }}>
                                    <p style={{ fontSize: 24, fontWeight: 'bold', color: 'rgba(61, 39, 77, 1)', marginBottom: 10, fontFamily: FONT.primaryFont }}>{item.title}</p>
                                    <p style={{ textAlign: 'justify', flex: 1, color: 'rgba(75, 75, 75, 1)' }}>{item.paragraph}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default StandardsAndControls;
