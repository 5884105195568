// src/pages/WhatWeDo.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import NavBar from './NavBar';
import { FONT } from '../utils/Font'

const WhatWeDoNew = () => {
    const { category } = useParams();

    // Function to get content based on category
    const getContent = (category) => {
        switch (category) {
            case 'service1':
                return (
                    <>
                        <h3 style={{fontFamily: FONT.fontBold,}}>Launch Your MVP with Speed and Quality.</h3>
                        <p style={{fontFamily:FONT.primaryFont,}}>Bring your product idea to life quickly and cost-effectively with our MVP development services. We specialize in helping you create a high-quality Minimum Viable Product to test your market hypothesis, validate demand, and gather valuable customer feedback for future enhancements. We understand the importance of budget-conscious development without compromising on quality. Our approach ensures that you can swiftly enter the market with a simplified version of your product, gain insights from early adopters, and make informed decisions for further development.</p>
                        <h4 style={{fontFamily: FONT.fontBold,}}>Our MVP Development Process</h4>
                        <p style={{fontFamily:FONT.primaryFont,}}> <span style={{fontFamily: FONT.fontBold,}}>1. Define MVP Scope:</span> We begin with an in-depth session where our experts collaborate with you to understand your vision from both a technical and business perspective. Together, we'll identify and prioritize the essential features for your MVP, ensuring a clear focus on what matters most. This tailored approach allows you to confidently launch your product and test key assumptions in the marketplace.</p>
                        <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>2. UI Design and Prototype:</span> We translate your MVP scope into actionable user stories and UX personas, providing clarity on what needs to be built and how users will interact with it. Our team works closely with you to refine these elements, leading to the creation of a functional prototype. With our extensive experience, we ensure that the resulting product not only meets but exceeds your expectations, offering an exceptional user experience.</p>
                        <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>3. Design and Develop:</span> Our development team excels in a wide range of programming languages, frameworks, and tools, ensuring that your MVP is built using the latest technologies. We focus on creating a product that is not only functional but also compatible with the latest platforms and devices, setting the stage for a successful market entry.</p>
                        <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>4. Test and Deploy:</span> We adhere to industry best practices for software quality control, including strict coding standards, regular code reviews, and comprehensive testing. Our approach combines manual and automated testing to identify and resolve issues early, ensuring that your software meets all specified requirements. Through continuous integration and delivery, we guarantee quick and reliable deployment, allowing you to bring your product to market with confidence.</p>
                    </>
                )
            case 'service2':
                return(
                    <>
                    <h3 style={{fontFamily: FONT.fontBold,}}>New Product Development</h3>
                    <p style={{fontFamily:FONT.primaryFont,}}>Looking to create a new product from the ground up or enhance an existing one? Our new product development services are designed to deliver innovative software solutions that meet the highest standards of quality, reliability, and scalability. Whether you’re starting fresh or refining your current offerings, we’re committed to bringing your vision to life.</p>
                    <p style={{fontFamily:FONT.primaryFont,}}>Our process is rooted in agile methodologies and a collaborative approach, ensuring that we deliver software that not only meets but exceeds your expectations. Equipped with the latest technologies, our team works closely with you at every stage, from initial concept to final deployment, to create a product that aligns perfectly with your business needs.</p>
                    <h4 style={{fontFamily: FONT.fontBold,}}>Key Features:</h4>
                    <p style={{fontFamily:FONT.primaryFont,}}> <span style={{fontFamily: FONT.fontBold,}}>1.Agile Development:</span> We use agile methodologies to adapt to your evolving requirements and deliver high-quality solutions efficiently.</p>
                    <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>2.Thorough Testing:</span>Every stage of development includes rigorous testing and quality assurance to ensure optimal performance.</p>
                    <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>3.Ongoing Support:</span> After the development process is complete, we provide continuous support to help you maintain and enhance your software solution over time.</p>
                    
                </>
                )
            case 'service3':
                return (
                    <>
                     <h3 style={{fontFamily: FONT.fontBold,}}>Mobile Application Development</h3>
                    <p style={{fontFamily:FONT.primaryFont,}}>Our expertise in mobile application development allows us to create custom, user-friendly, and highly efficient apps tailored to your specific business requirements. Whether you need an app for iOS, Android, or cross-platform use, we leverage the latest technologies to deliver solutions optimized for speed and performance.</p>
                    <h4 style={{fontFamily: FONT.fontBold,}}>Our Process:</h4>
                    <p style={{fontFamily:FONT.primaryFont,}}> <span style={{fontFamily: FONT.fontBold,}}>1. Collaborative Planning:</span> We start by understanding your needs, target audience, and the purpose of your app. This involves close collaboration with your team to create detailed wireframes and mockups that visualize the design and functionality.</p>
                    <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>2. Expert Development:</span> With expertise in programming languages like Swift, Objective-C, Java, Kotlin, and React Native, our development team builds robust apps that handle complex features such as GPS computation, collaboration tools, and payment systems.</p>
                    <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>3. Rigorous Testing:</span> Our commitment to quality means that every app undergoes rigorous testing to ensure it’s bug-free and performs as expected. We also provide ongoing updates to keep your app optimized for the latest devices and operating systems.</p>
                    <h4 style={{fontFamily: FONT.fontBold,}}>Technologies We Use:</h4>
                    <p style={{fontFamily:FONT.primaryFont,}}> <span style={{fontFamily: FONT.fontBold,}}>1. iOS App Development:</span> At Intellection Software, we possess the expertise to create fully-fledged, stable, and scalable iOS applications that meet your business needs. Our team specializes in the following technologies to ensure that your app is not only functional but also optimized for performance:</p>
                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • Swift:</span> Leverage the power of Swift, Apple’s modern programming language, to build fast, secure, and high-performing iOS applications.</p>

                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • Objective-C:</span> For projects requiring legacy support or complex integrations, our expertise in Objective-C ensures seamless compatibility and robust functionality.</p>
                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • SwiftUI:</span> We utilize SwiftUI for building dynamic user interfaces that are both visually appealing and highly responsive across all Apple devices.</p>
                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • CocoaPods:</span> Our use of CocoaPods allows for the efficient integration of third-party libraries, ensuring that your app is feature-rich and easy to maintain.</p>

                    <p style={{fontFamily:FONT.primaryFont,}}><span style={{fontFamily: FONT.fontBold,}}>2. Android App Development:</span>We empower businesses to establish a strong presence on Android devices by developing apps that are both cutting-edge and user-friendly. Utilizing the latest technologies, our Android app development services include:</p>
                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • Kotlin:</span> We use Kotlin, the preferred language for Android development, to create apps that are not only concise and efficient but also highly maintainable.</p>

                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • Java:</span> With our deep knowledge of Java, we can build versatile Android applications that are compatible with a wide range of devices and OS versions.</p>
                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • Gradle:</span> Our expertise in Gradle ensures that your Android apps are built with a robust and flexible build system, optimizing performance and speeding up the development process.</p>
                   
                    </>
                )
           
            case 'service4':
                return (
                    <>
                    <h4 style={{fontFamily: FONT.fontBold,}}>Cross-platform App Development </h4>
                    <p style={{fontFamily:FONT.primaryFont,}}>Intellection Software offers top-tier cross-platform app development services that allow you to reach a broader audience without compromising on quality. By choosing a cross-platform solution, you can reduce development costs and time-to-market while delivering a consistent user experience across different operating systems:</p>
                    
                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • React Native:</span> We create high-performance mobile apps with React Native, enabling your app to run smoothly on both iOS and Android platforms with a single codebase.</p>

                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • Flutter:</span> Our team uses Flutter to build visually stunning and natively compiled applications that offer seamless performance across multiple platforms.</p>
                    <p style={{fontFamily:FONT.primaryFont,marginTop:'-10px',marginLeft:'15px'}}><span style={{fontFamily: FONT.fontBold,}}>
                    • NativeScript:</span> With NativeScript, we develop cross-platform apps that provide a truly native user experience, leveraging the full potential of each operating system's capabilities.</p>
                   
                    </>
                )
            default:
                return <p>Select a service from the dropdown.</p>;
        }
    };

    const content = getContent(category);

    return (
        <>
            <NavBar />
            <div className='WhatWeDoComponent' style={{ marginTop: '100px', marginBottom: '100px' }}>
                {/* <h1>{category || 'Service Details'}</h1> */}
            {content} 

            </div>
        </>
    );
};

export default WhatWeDoNew;
