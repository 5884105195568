import React, { useEffect } from 'react';

const LinkedInTracking = () => {
  useEffect(() => {
    // LinkedIn partner ID script
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.innerHTML = `
      _linkedin_partner_id = "7607937";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;
    document.head.appendChild(script1);

    // LinkedIn tracking script
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.innerHTML = `
      (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
    `;
    document.head.appendChild(script2);

    // Return cleanup function to remove scripts on unmount
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src="https://px.ads.linkedin.com/collect/?pid=7607937&fmt=gif"
      />
    </noscript>
  );
};

export default LinkedInTracking;
