import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

import NavBar from './NavBar';
import datascience from '../assets/images/datascience.png'
const AboutUsMore = () => {
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBar />
            <div className="AboutUsHome" style={{ marginTop: '100px',marginBottom:'100px' }}>
                <Row>
                    <Col md={7}>
                        <h3>Welcome To Intellection</h3>
                        <p>At Intellection, we are committed to delivering excellence in software development, a commitment that has been unwavering since our inception by esteemed alumni of the Indian Institute of Technology Bombay (IIT Bombay). With over 15 years of industry experience, we have established ourselves as leaders in creating sophisticated enterprise-level web and mobile applications, empowering our clients to achieve new levels of success.</p>
                        <p>Our expertise extends beyond traditional software development; we are pioneers in data science, utilizing advanced analytics and machine learning to transform complex datasets into actionable insights. This deep technical proficiency allows us to offer innovative solutions that are not only robust and reliable but also strategically aligned with our clients' business objectives. Our global footprint spans the USA, Europe, Australia, and Asia, reflecting our ability to deliver high-impact solutions across diverse markets.</p>
                        <p>At Intellection, we take pride in the long-standing partnerships we have cultivated with our clients, a testament to the trust they place in our capabilities. Our dedication to exceeding expectations is evident in the repeat business and glowing feedback we consistently receive. We prioritize responsive communication and a deep understanding of our clients’ evolving needs, ensuring that our solutions are as intuitive as they are effective.</p>
                        <p>Our mission is to be more than just a service provider. We aim to be a dependable partner in your digital transformation journey. By staying at the forefront of technological advancements and maintaining an unwavering focus on quality, we strive to deliver software solutions that drive real value, helping our clients navigate the complexities of the modern business landscape with confidence and success.</p>
                    </Col>
                    <Col md={5}>
                        <img src={datascience} alt="Data Science" className="img-fluid" />
                    </Col>
                </Row>

            </div>
        </>
    )
}
export default AboutUsMore