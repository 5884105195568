import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "company": "Company",
        "about_us": "About Us",
        "leadership": "Leadership",
        "testimonials": "Testimonials",
        "services": "Services",
        "ourwork": "Portfolio",
        "media_entertainment": "Media & Entertainment",
        "healthcare": "Healthcare",
        "utility_solution": "Utility Solution",
        "manufacturing": "Manufacturing",
        "education": "Education",
        "our_products": "Products",
        "products_platforms": "Products & Platforms",
        "products_description": "Some description here...",
        "careers": "Careers",
        "contact_us": "Contact Us",
        "english": "English",
        "french": "French",
        "japanese": "Japanese",
      },
    },
    de: {
      translation: {
        "company": "Unternehmen",
        "about_us": "Über uns",
        "leadership": "Führung",
        "testimonials": "Erfahrungsberichte",
        "services": "Dienstleistungen",
        "ourwork": "Portfolio",
        "media_entertainment": "Medien & Unterhaltung",
        "healthcare": "Gesundheitswesen",
        "utility_solution": "Versorgungslösung",
        "manufacturing": "Herstellung",
        "education": "Bildung",
        "our_products": "Unsere Produkte",
        "products_platforms": "Produkte & Plattformen",
        "products_description": "Eine Beschreibung hier...",
        "careers": "Karriere",
        "contact_us": "Kontaktieren Sie uns",
        "english": "Englisch",
        "french": "Französisch",
        "japanese": "Japanisch",
      },
    },
    jp: {
      translation: {
        "company": "会社",
        "about_us": "私たちに関しては",
        "leadership": "リーダーシップ",
        "testimonials": "お客様の声",
        "services": "サービス",
        "ourwork": "ポートフォリオ",
        "media_entertainment": "メディア＆エンターテイメント",
        "healthcare": "ヘルスケア",
        "utility_solution": "ユーティリティソリューション",
        "manufacturing": "製造",
        "education": "教育",
        "our_products": "私たちの製品",
        "products_platforms": "製品とプラットフォーム",
        "products_description": "ここに説明があります...",
        "careers": "キャリア",
        "contact_us": "お問い合わせ",
        "english": "英語",
        "french": "フランス語",
        "japanese": "日本語",
      },
    },
  },
  lng: 'en', // default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
