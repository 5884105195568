import React from 'react';
import { Col, Row } from 'react-bootstrap';
import education from '../assets/images/educationnew.png';
import { COLORS } from '../utils/Colors';
import { FONT } from '../utils/Font';

const Data = [
    {
        img: education,
        heading: 'Innovation',
    },
    {
        img: education,
        heading: 'Transparency',
    },
   
    {
        img: education,
        heading: 'Consistency',
    },
    {
        img: education,
        heading: 'Trust',
    },
];

const OurValues = () => {
    return (
        <div className='Experience' style={{ marginTop: 10, marginBottom:20 }}>
            <p
                style={{
                    color: 'rgba(41, 41, 41, 1)',
                    fontSize: 40,
                    fontWeight: 600,
                    fontFamily: FONT.primaryFont,
                    textAlign: 'center',
                }}
            >
                Our Core <span className="highlighted"> Values</span>
      </p>
            <Row>
                {Data.map((item, index) => (
                    <Col key={index} md={3} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',  
                            alignItems: 'center',     
                        }}>
                            <div style={{
                                backgroundColor: 'rgba(246, 247, 248, 1)',
                                borderRadius: '50%',
                                width: 150,
                                height: 150,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: 20,
                            }}>
                                <img
                                    src={item.img}
                                    alt={item.heading}
                                    height={70}
                                    width={70}  // Corrected typo in 'width'
                                />
                            </div>
                        </div>

                        <p
                            style={{
                                color: 'rgba(41, 41, 41, 1)',
                                fontSize: 20,
                                fontWeight: 600,
                                fontFamily: FONT.primaryFont,
                                textAlign: 'center',
                                marginTop: '0px',
                            }}
                        >
                            {item.heading}
                        </p>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default OurValues;
