import React, { useEffect, useState } from 'react'
import { Row, Col, Card } from "react-bootstrap";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavBar from '../Components/NavBar'
import "../Components/NavBar.css"
import ContactWithUs from '../Components/ContactWithUs'

const Blog = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/blogs-category.php`);
                setData(response.data);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCardClick = (slug) => {
        console.log(slug)
        navigate(`/${slug}`);
    };

    return (
        <>
            <NavBar />
            <div  style={{ padding: '0px 60px', marginTop: 100, marginBottom: 40 }} >
                <h1>Blog</h1>
                <p>Customized solutions that have helped our clients achieve their specific business goals.</p>  
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {!loading && !error && (
                    <Row>
                        {data.map((item, index) => (
                            <Col key={index} lg={4} md={4} sm={4} className="carousel-item-col">
                                <Card className="text-white" style={{ marginBottom: 20 }} onClick={() => handleCardClick(item.slug)}>
                                    <Card.Img className="img-hover" src={item.image} alt={item.alt} />
                                    <div className="img-overlay">
                                        <Card.Title>{item.name}</Card.Title>
                                        <Card.Text>{item.description}</Card.Text>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}             
            </div>
            <ContactWithUs />
        </>
    )
}

export default Blog