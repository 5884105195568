import React from 'react'
import Contact from '../Components/Contact'
import NavBar from '../Components/NavBar'
import Navv from '../Components/Navv'
import { Helmet } from 'react-helmet';

const ContactUs =()=>{
    React.useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
        <Helmet>
                <title>Intellection: ContactUs</title>
                <meta name="description" content="Software Development Company in Pune, Maharashtra, Software Development Company in India,End-to-end software development services in India,Innovative technology solutions provider in Pune,Custom CRM development services in India" />

                <meta name="keywords" content="Enterprise application development company in India,Software Solutions in Pune, End-to-End Software Services,IT Consulting Services,Data Analytics Solutions,IT Services in Pune,Software Innovation in India,AI-Driven Software Solutions,End-to-end software development services in India,Innovative technology solutions provider in Pune,Custom CRM development services in India," />
                <meta name="robots" content="index, follow" />
                {/* Open Graph tags for social sharing */}
                <meta property="og:title" content="Innovative Solutions for Your Business" />
                <meta property="og:description" content="Explore our innovative services and solutions for industries across the globe. We deliver expertise in various sectors." />
                {/* <meta property="og:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
                <meta property="og:url" content="https://www.intellection.in/company-overview" />
                <meta property="og:type" content="website" />
                {/* <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - Innovative Solutions for Your Business" />
                <meta name="twitter:description" content="Explore our innovative services and solutions for industries across the globe." />
                <meta name="twitter:image" content="https://www.yourwebsite.com/path-to-your-image.jpg" /> */}
            </Helmet>
        <NavBar />
        <Contact />
        </>
    )
}
export default ContactUs