import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { COLORS } from '../utils/Colors'
import { FONT } from '../utils/Font'
import Ideation from '../assets/images/Ideation.png'
import Exceution from '../assets/images/Exceution.png'
import leftImage from '../assets/images/lefttImage.png'
import rightImage from '../assets/images/rightImage.png';
import TechStackCardComponent from './TechStackCardComponent'
import Speed from '../assets/images/speed.png'
import Development from '../assets/images/development.png'
import Communication from '../assets/images/communication.png'

import './Navv.css'
// const Data = [
//     {
//         img: Ideation,
//         heading: "Brilliant Ideation"
//     },
//     {
//         img: Exceution,
//         heading: "Flawless Execution"
//     },
// ]

const Opening = [
    { language: 'React Js' },
    { language: 'React Native' },
    { language: 'Full Stack Developer' },
    { language: 'Java Developer' },
    { language: 'UI/UX Designer' },
    { language: 'Graphic Designer' },
]

const Data = [
    {
        title: "Graphic Designer",
        list: [
            "Key Skills: Adobe, Photoshop, Figma, Canva",
            "Location: Kothrud, Pune",
            // "Employment Type: Internship",
        ],
        img: Speed
    },
    {
        title: "Front-End Developer",
        list: [
            "Key Skills: ReactJs, Javascript(ES6),",
            "Location: Kothrud, Pune",
            // "Employment Type: Internship",
        ],
        img: Communication
    },
    {
        title: "Back-End Developer",
        list: [
            "Key Skills: Python, MySQL, Django",
            "Location: Kothrud, Pune",
            // "Employment Type: Internship",
        ],

        img: Development
    },

];

const Careers = () => {
    return (
        <>
            <div style={{
                marginTop: '5%',
                padding: 50,
                textAlign: 'center',
                background: 'linear-gradient(180deg, rgba(111, 45, 168, 0.03) -54.1%, #FFFFFF 63.14%)',
                position: 'relative'
            }}>
                <img
                    src={leftImage}
                    alt="Left Decorative"
                    className="leftImage"
                    style={{
                        position: 'absolute',
                        top: 50,
                        left: 0,
                        height: '170px',
                        width: '300px'
                    }}
                />
                <img
                    src={rightImage}
                    alt="Right Decorative"
                    className="leftImage"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        width: 'auto'
                    }}
                />
                <p style={{ fontFamily: FONT.fontBold, fontWeight: 700, fontSize: 45, marginTop: 50 }}>
                    Careers at <span style={{ color: 'rgba(111, 45, 168, 1)' }}>Intellection</span>
                </p>
                <div style={{
                    textAlign: 'center',
                    margin: '0 auto',

                }}>
                    <p style={{ fontFamily: FONT.primaryFont, fontSize: 20 }}>
                        At Intellection, we thrive on challenging responsibilities. Our belief
                    </p>
                    <p style={{ fontFamily: FONT.primaryFont, fontSize: 20, marginTop: '-15px' }}>is that growth comes from embracing the tough tasks.</p>
                </div>
            </div>
            {/* <div style={{ textAlign: 'center', fontFamily: FONT.primaryFont, }}>
                <h2 style={{ fontFamily: FONT.fontBold, }}>Join Our Team</h2>
                <p>We're always looking for talented individuals to join our software company. If you're passionate about technology and innovation, we'd love to hear from you.</p>

            </div> */}
            <div className='WhatWeDoComponent' style={{ marginBottom: 40 }}>
                <div style={{ marginTop: 0 }}>
                    <p style={{ color: 'rgba(0, 0, 0, 1)', fontFamily: FONT.primaryFont, fontSize: 42, fontWeight: 'bold', textAlign: 'center' }}>Current Openings</p>


                    <Row style={{ marginTop: 5 }}>
                        {Data.map((item, index) => (
                            <Col lg={4} md={6} sx={12} key={index} className="d-flex">
                                <div className="custom-container">
                                    <img src={item.img} width={50} height={50} alt="Item" />
                                    <p className="item-title" style={{ fontFamily: FONT.fontBold }}>
                                        {item.title}
                                    </p>
                                    <ul className="item-list" style={{ fontFamily: FONT.primaryFont }}>
                                        {item.list.map((listItem, listIndex) => {
                                            // Split the text into the label and the rest of the content
                                            const [label, ...value] = listItem.split(':');
                                            return (
                                                <li key={listIndex}>
                                                    <span style={{ fontFamily: FONT.fontBold }}>{label}:</span>
                                                    <span style={{ fontFamily: FONT.primaryFont }}>{value.join(':')}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </Col>
                            // <TechStackCardComponent item={item} index={index} />
                        ))}
                    </Row>
                    <div style={{ textAlign: 'center', fontFamily: FONT.primaryFont, marginTop: 25 }}>
                        <h2 style={{ fontFamily: FONT.fontBold, }}>Join Our Team</h2>
                        <p>We're always looking for talented individuals to join our software company. If you're passionate about technology and innovation, we'd love to hear from you.</p>
                        <p style={{ textAlign: 'center', }}>Please send your resume to <a href="mailto:careers@intellection.in">careers@intellection.in</a></p>
                    </div>


                </div>
            </div>
        </>
    )
}
export default Careers