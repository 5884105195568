import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AboutUsHome from '../Components/AboutUsHome';
import Experience from '../Components/Experience';
import NavBar from '../Components/NavBar';
import OurExpertise from '../Components/OurExpertise';
import OurServices from '../Components/OurServices';
import ProjectCaseStudies from '../Components/ProjectCaseStudies';
import Testimonial from '../Components/Testimonial';
import OurClient from '../Components/OurClient';
import ImgCard from '../Components/ImgCard';
import GlobalMap from '../Components/GlobalMap';
import ContactWithUs from '../Components/ContactWithUs';
import OurWorkFlow from '../Components/OurWorkFLow';
import CarouselComponent from '../Components/CarouselComponent';
import WhyChooseUs from '../Components/WhyChooseUs';
import TechStack from '../Components/TechStack';
import TechServices from '../Components/TechServices';

const Home = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
         <Helmet>
                <title>Innovative Software Solution Provider</title>
                <meta name="description" content="Explore innovative software solutions designed to propel your business forward. We bring your ideas to life with our expertise and cutting-edge technology." />
                <meta name="keywords" content="Agile Software Development,Predictive Analytics Services,software solutions, innovative technology, business development,End to End Software Product, Building an MVP, Software Solutions Partner, Technology Partner" />
                <meta property="og:title" content="Innovative Software Solutions - Your Idea + Our Expertise" />
                <meta property="og:description" content="We transform your ideas into high-quality, market-ready products with our expert software solutions." />
                <meta property="og:url" content="https://www.intellection.in/" />
            </Helmet>
            <NavBar />
            <ImgCard />
            <Experience />
            <WhyChooseUs />
            <GlobalMap />
            {/* <AboutUsHome /> */}
            <OurServices />
            {/* <TechServices /> */}
            {/* <OurWorkFlow /> */}
            <OurExpertise title="Industries We Deliver Innovative Solutions" />
            <ContactWithUs />
            <OurClient />
            {/* <ProjectCaseStudies /> */}
            {/* <Testimonial /> */}
        </>
    );
}

export default Home;
